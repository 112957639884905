import { getLocalStorageItem } from '@utils/storage';
import { StorageKeys } from '@constants/storage';
import NotifierStack from '@containers/notification/Notification';

import PublicLayout from './PublicLayout';
import PrivateLayout from './PrivateLayout';

const RouteLayout = () => {
  const isLoggedIn = getLocalStorageItem(StorageKeys.TOKEN);
  return (
    <>
      {isLoggedIn ? <PrivateLayout /> : <PublicLayout />}
      <NotifierStack />
    </>
  );
};

export default RouteLayout;

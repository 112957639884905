/* eslint-disable import/no-cycle */
import { Mutex } from 'async-mutex';
import { createApi } from '@reduxjs/toolkit/query/react';
import { GraphQLClient } from 'graphql-request';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';

import { getLocalStorageItem, setTokensInStorage } from '@utils/storage';
import { REFRESH } from '@containers/auth/store/queries';
import { StorageKeys } from '@constants/storage';

import { CustomBaseQueryFn, IRefreshResponse } from './type';
import { isTokenExpired, resetAndRedirectToLogin } from './utils';
import { graphQLOptions } from './options';

const errorHandler = { handlerFunction: (message?: string) => null };
export const setErrorHandler = (
  handlerFunction: (message?: string) => void
) => {
  errorHandler.handlerFunction = handlerFunction;
};

const mutex = new Mutex();

const createNewClient = (newToken) => {
  const token = newToken || getLocalStorageItem(StorageKeys.TOKEN);
  return new GraphQLClient(`${process.env.REACT_APP_API_ENDPOINT}/graphql`, {
    ...graphQLOptions,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// eslint-disable-next-line import/no-mutable-exports
let client: GraphQLClient;

export const createNewGQLBaseQuery = (token) => {
  client = createNewClient(token);
  const gqlBaseQuery = graphqlRequestBaseQuery({ client });
  return gqlBaseQuery;
};

let gqlBaseQuery = createNewGQLBaseQuery(null);

const customFetchGQLBaseQuery: CustomBaseQueryFn = async (
  arg,
  api,
  extraOptions
) => {
  try {
    await mutex.waitForUnlock();
    let result = await gqlBaseQuery(arg, api, extraOptions);
    if (result.error?.message && isTokenExpired(result.error.message)) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const response: IRefreshResponse = await client.request(REFRESH, {
            refreshToken: getLocalStorageItem(StorageKeys.REFRESH_TOKEN),
          });
          if (response?.refresh) {
            setTokensInStorage(response.refresh);
            gqlBaseQuery = createNewGQLBaseQuery(response.refresh.accessToken);
            result = await gqlBaseQuery(arg, api, extraOptions);
          } else resetAndRedirectToLogin();
        } catch (error) {
          resetAndRedirectToLogin();
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await gqlBaseQuery(arg, api, extraOptions);
      }
    } else if (result.error) {
      if (
        ((result.meta as any)?.response?.errors[0]?.message as string).includes(
          'UNAUTHORIZED'
        )
      )
        resetAndRedirectToLogin();
      errorHandler.handlerFunction(
        (result.meta as any)?.response?.errors[0]?.message
      );
    }
    return result;
  } catch (error) {
    errorHandler.handlerFunction();
    return error;
  }
};

const api = createApi({
  reducerPath: 'api',
  baseQuery: customFetchGQLBaseQuery,
  endpoints: () => ({}),
});

export default api;
export { client };

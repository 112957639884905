import { createSlice } from '@reduxjs/toolkit';

import {
  DeviceSlice,
  INumberPayload,
  IOptionTypePayload,
  IStringPayload,
} from '../type';

const filtersInitialState = {
  deviceSearchText: '',
  selectedUser: null,
  page: 1,
  userSearchText: '',
  userPage: 1,
  roomSearchText: '',
  roomPage: 1,
  selectedRoom: null,
  selectedCategory: null,
  selectedUserType: null,
  selectedVendor: null,
  selectedModelName: null,
  selectedCountry: null,
};

export const initialState: DeviceSlice = {
  filters: {
    deviceList: filtersInitialState,
    userDeviceList: filtersInitialState,
  },
  totalDeviceCount: 0,
};

export const deviceSlice = createSlice({
  name: 'Device',
  initialState,
  reducers: {
    updateDeviceSearchText: (state, { payload }: IStringPayload) => {
      const { data, type } = payload || {};
      state.filters[type].deviceSearchText = data;
    },
    updateSelectedUserType: (state, { payload }: IOptionTypePayload) => {
      const { data, type } = payload || {};
      state.filters[type as string].selectedUserType = data;
    },
    updateSelectedUser: (state, { payload }: IOptionTypePayload) => {
      const { data, type } = payload || {};
      state.filters[type as string].selectedUser = data;
    },
    updatePage: (state, { payload }: INumberPayload) => {
      const { data, type } = payload || {};
      state.filters[type].page = data;
    },
    updateUserSearchText: (state, { payload }: IStringPayload) => {
      const { data, type } = payload || {};
      state.filters[type].userSearchText = data;
    },
    updateUserPage: (state, { payload }: INumberPayload) => {
      const { data, type } = payload || {};
      state.filters[type].userPage = data;
    },
    updateRoomSearchText: (state, { payload }: IStringPayload) => {
      const { data, type } = payload || {};
      state.filters[type].roomSearchText = data;
    },
    updateRoomPage: (state, { payload }: INumberPayload) => {
      const { data, type } = payload || {};
      state.filters[type].roomPage = data;
    },
    updateSelectedVendor: (state, { payload }: IOptionTypePayload) => {
      const { data, type } = payload || {};
      state.filters[type as string].selectedVendor = data;
    },
    updateSelectedRoom: (state, { payload }: IOptionTypePayload) => {
      const { data, type } = payload || {};
      state.filters[type as string].selectedRoom = data;
    },
    updateSelectedCountry: (state, { payload }: IOptionTypePayload) => {
      const { data, type } = payload || {};
      state.filters[type as string].selectedCountry = data;
    },
    updateSelectedCategory: (state, { payload }: IOptionTypePayload) => {
      const { data, type } = payload || {};
      state.filters[type as string].selectedCategory = data;
    },
    updateSelectedModelName: (state, { payload }: IOptionTypePayload) => {
      const { data, type } = payload || {};
      state.filters[type as string].selectedModelName = data;
    },
    updateTotalDeviceCount: (state, { payload }) => {
      state.totalDeviceCount = payload;
    },
  },
});

export const {
  updateDeviceSearchText,
  updateSelectedUser,
  updateSelectedUserType,
  updatePage,
  updateUserSearchText,
  updateUserPage,
  updateRoomSearchText,
  updateRoomPage,
  updateSelectedRoom,
  updateSelectedCategory,
  updateTotalDeviceCount,
  updateSelectedVendor,
  updateSelectedModelName,
  updateSelectedCountry,
} = deviceSlice.actions;

export default deviceSlice.reducer;

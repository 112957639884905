import { DeviceVendor } from '@customTypes/common';

export const preventDefaultEvents = (event: React.MouseEvent) => {
  event.stopPropagation();
  event.preventDefault();
};

export const getDeviceVendor = (vendor: string): string => {
  switch (vendor) {
    case DeviceVendor.GREE:
      return 'Gree';
    case DeviceVendor.TUYA:
    default:
      return 'Tuya';
  }
};

import { createSlice } from '@reduxjs/toolkit';

import { UserSearchType } from '@constants/common';
import { OptionType } from 'components/dropdown/types';
import { UserFilters, IUserPermissions, UserSlice } from '../type';

const filtersInitialState: UserFilters = {
  pageIndex: 1,
  userSearchText: '',
  userSearchType: UserSearchType[1],
  userSelectedCountry: null,
};

export const initialState: UserSlice = {
  filters: {
    user: filtersInitialState,
  },
  totalUserCount: 0,
  userPermissions: [],
};

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    updatePageIndex: (state, { payload }: { payload: number }) => {
      state.filters.user.pageIndex = payload;
    },
    updateUserSearchType: (state, { payload }: { payload: OptionType }) => {
      state.filters.user.userSearchType = payload;
    },
    updateUserSearchText: (state, { payload }: { payload: string }) => {
      state.filters.user.userSearchText = payload;
    },
    updateUserPermissions: (
      state,
      { payload }: { payload: IUserPermissions[] }
    ) => {
      const permissions = payload.map((permission) => permission.name);
      state.userPermissions = [...permissions];
    },
    updateSelectedCountry: (state, { payload }: { payload: OptionType }) => {
      state.filters.user.userSelectedCountry = payload;
    },
  },
});

export const {
  updatePageIndex,
  updateUserSearchText,
  updateUserPermissions,
  updateUserSearchType,
  updateSelectedCountry,
} = userSlice.actions;

export default userSlice.reducer;

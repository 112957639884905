import { emailRegex } from '@constants/common';

const isNonEmptyString = (str: string) =>
  Boolean(str && typeof str === 'string' && str.length);

const checkIsValidEmail = (email: string): boolean => {
  if (typeof email === 'string') return emailRegex.test(email);
  return false;
};

export { isNonEmptyString, checkIsValidEmail };

import React, { useRef, useState } from 'react';

import { Calendar, ErrorWarningIcon } from '@icons';
import { getFormattedDates } from '@utils/date';
import { useClickOutside } from '@hooks';
import { preventDefaultEvents } from '@utils/generics';

import DatePicker from '../date-picker/DatePicker';
import ClearButton from '../clear-button/ClearButton';

import { IDateRangeInputProps } from './types';

const DateRangeInput: React.FC<IDateRangeInputProps> = (props) => {
  const {
    dateFilters,
    errorMessage,
    isDateRangePicker = true,
    isDisabled = false,
    isLoading,
    label,
    maxDate,
    minDate,
    submitBtnStyle,
    onSubmit,
  } = props;

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    ref: containerRef,
    handler: () => setIsDatePickerVisible(false),
  });

  const handleSubmit = (start: Date, end: Date) => {
    onSubmit(start, end);
    setIsDatePickerVisible(false);
  };

  const toggleDatePickerVisibility = () => {
    if (!isDisabled) setIsDatePickerVisible((prev) => !prev);
  };

  const clearSelectedItems = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    preventDefaultEvents(e);
    onSubmit(null, null);
  };

  const getPlaceHolder = () => {
    if (isDateRangePicker) return 'Select Date Range';
    if (!isDisabled) return 'Select Date';
    return '';
  };

  const formattedDates = getFormattedDates(dateFilters, 'DD/MM/YYYY');
  const isDateSelected = dateFilters.startDate && dateFilters.endDate;

  const onDatePickerFocus = () => {
    const parentDiv = document.getElementById('main-container');
    const popupDiv = document.getElementById('date-picker');

    // Calculate the scroll position to make the popup visible
    const parentRect = parentDiv.getBoundingClientRect();
    const popupRect = popupDiv.getBoundingClientRect();
    const popupTopOffset = popupRect.top - parentRect.top;
    const scrollPosition = parentDiv.scrollTop + popupTopOffset;

    // Scroll the parent div to the calculated scroll position
    parentDiv.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  };
  return (
    <>
      {label && !isLoading && (
        <div
          className={`${
            isLoading ? 'input-card h-[15px] w-[72px]' : ''
          } text-sm text-gray-600 mb-2`}
        >
          {isLoading ? '' : label}
        </div>
      )}

      {isLoading ? (
        <div className='input-card h-full w-full' />
      ) : (
        <div
          className={`flex items-center border rounded-lg pl-3 h-full ${
            isDisabled
              ? 'bg-gray-50 text-gray-600 cursor-not-allowed'
              : 'border-gray-400 bg-white'
          } ${errorMessage ? 'border-error' : ''}`}
          ref={containerRef}
          role='presentation'
          onClick={toggleDatePickerVisibility}
        >
          <div className='relative w-full'>
            <div className='flex justify-between w-full'>
              <div className='flex gap-2 items-center text-jaguar'>
                {!isDisabled && <Calendar className='w-4' />}
                {isDateSelected ? (
                  <span className='text-sm'>
                    {formattedDates.startDate}{' '}
                    {isDateRangePicker ? `- ${formattedDates.endDate}` : ''}
                  </span>
                ) : (
                  <span className='text-sm border border-white select-none'>
                    {getPlaceHolder()}
                  </span>
                )}
              </div>
              {isDateSelected && !isDisabled && (
                <ClearButton onClear={clearSelectedItems} />
              )}
            </div>

            {isDatePickerVisible && (
              <div
                id='date-picker'
                className='prevent-select -left-2 bg-white absolute top-10 z-2 p-3 border border-gray-400 rounded-lg'
                role='presentation'
                onClick={preventDefaultEvents}
                onFocus={onDatePickerFocus}
              >
                <DatePicker
                  defaultEndDate={dateFilters.endDate}
                  defaultStartDate={dateFilters.startDate}
                  maxDate={maxDate}
                  minDate={minDate}
                  onSubmit={handleSubmit}
                  isDateRangePicker={isDateRangePicker}
                  submitBtnStyle={submitBtnStyle}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {errorMessage && (
        <div className='text-xs mt-2 flex items-center text-error'>
          <ErrorWarningIcon className='mr-2' />
          Date is required
        </div>
      )}
    </>
  );
};

export default DateRangeInput;

import React, { useCallback } from 'react';
import TimePicker from 'react-time-picker';

import { Cross } from '@icons';
import { splitTime } from '@utils/date';

import { ITimePickProps } from './type';
import './styles.css';

const TimePick = ({ isLoading, onChange, value, disabled }: ITimePickProps) => {
  const handleDateSelect = useCallback(() => {
    const { hour, min, sec } = splitTime(value);
    const correctedTime = {
      hour: `${hour.toString()}:`,
      min: `${min.toString()}:`,
      sec: sec.toString(),
    };
    if (hour === 2 || (sec > 5 && sec.toString().length === 1)) {
      if (hour === 2) correctedTime.hour = '2:';
      if (sec > 5 && sec.toString().length === 1)
        correctedTime.sec = sec.toString();
      onChange(`${correctedTime.hour + min.toString()}:${correctedTime.sec}`);
    }
  }, [value, onChange]);

  const renderTimePicker = (): JSX.Element => {
    if (disabled) {
      return (
        <div
          className='h-full rounded-lg outline-none min-w-[180px] border gap-3
        bg-gray-50 text-gray-600 cursor-not-allowed flex px-5 items-center'
        >
          {value?.replaceAll(':', ' : ')}
        </div>
      );
    }
    return (
      <TimePicker
        onChange={onChange}
        value={value}
        maxDetail='minute'
        disableClock
        className='h-full rounded-lg outline-none min-w-[180px] bg-white text-jaguar'
        clearIcon={value ? <Cross /> : null}
      />
    );
  };

  return (
    <div className='h-full' onBlur={handleDateSelect}>
      {isLoading ? (
        <div className='input-card h-full min-w-[180px]' />
      ) : (
        renderTimePicker()
      )}
    </div>
  );
};

export default TimePick;

import { combineReducers } from 'redux';

import Profile from '@containers/auth/store/reducer';
import Notifications from '@containers/notification/reducer';
import Devices from '@containers/devices/store/reducer';
import Breadcrumbs from '@containers/header/reducer';
import PushNotifications from '@containers/PushNotifications/store/reducer';
import Feedbacks from '@containers/feedbacks/store/reducer';
import Users from '@containers/users/store/reducer';
import Admins from '@containers/admins/store/reducer';
import Warranty from '@containers/warranty/store/reducer';

// eslint-disable-next-line import/no-cycle
import store from './store';

const rootReducer = combineReducers({
  Profile,
  Notifications,
  Devices,
  Breadcrumbs,
  PushNotifications,
  Feedbacks,
  Users,
  Admins,
  Warranty,
});

export type RootState = ReturnType<typeof store.getState>;

export default rootReducer;

import { gql } from 'graphql-request';

export const LOGIN = gql`
  mutation passwordLogin($username: String!, $password: String!) {
    passwordLogin(input: { username: $username, password: $password }) {
      accessToken
      refreshToken
    }
  }
`;

export const REFRESH = gql`
  mutation refresh($refreshToken: String!) {
    refresh(input: { refreshToken: $refreshToken }) {
      accessToken
      refreshToken
    }
  }
`;

export const GET_PROFILE = gql`
  query getProfile {
    getProfile {
      id
      email
      firstName
      lastName
    }
  }
`;

export const LOGOUT = gql`
  mutation logout($refreshToken: String!) {
    logout(input: { refreshToken: $refreshToken })
  }
`;

export const GET_PRESIGNED_URL = gql`
  query getPresignedUrl(
    $category: FileCategory!
    $urlType: UrlType
    $key: String
  ) {
    getPresignedUrl(
      input: { category: $category, urlType: $urlType, key: $key }
    ) {
      url
      fileName
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput) {
    resetPassword(input: $input)
  }
`;

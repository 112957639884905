import { DEFAULT_VALUE } from '@constants/common';

import { DateRange } from '@containers/insights/type';
import { IUser } from '../types/common';
import { getFormattedDate, getFullMonthAndYear } from './date';

export const nameFormatter = (user: IUser) => {
  const firstName = user?.firstName?.trim();
  const lastName = user?.lastName?.trim();
  if (firstName || lastName) return `${firstName} ${lastName}`;
  return DEFAULT_VALUE;
};

export const getUniqueList = (currentList = [], newList = []) => {
  // The getDeviceUsers query fetched for all users [owner + member], hence multiple devices with same device id & device name is present in the list. Here showing only the ones having unique device-id - device-name combo
  let combinedList = [...currentList, ...newList];
  if (newList.length) {
    combinedList = [
      ...new Map(
        combinedList.map((obj) => [JSON.stringify(obj), obj])
      ).values(),
    ];
  }
  return combinedList;
};

export const formatWithUnit = (value, unit = '', acceptZero = true) => {
  if (value) return `${value} ${unit}`;
  if (value === 0) return acceptZero ? `${value} ${unit}` : value;
  return DEFAULT_VALUE;
};

export const dateRangeDisplayFormat = (
  activeTabLabel: number,
  dateRange: DateRange
): string => {
  switch (activeTabLabel) {
    case 0:
      return `${getFormattedDate(dateRange.startDate, 'DD-MM-YYYY')} `;
    case 1:
      return `${getFormattedDate(
        dateRange.startDate,
        'DD-MM-YYYY'
      )} - ${getFormattedDate(dateRange.endDate, 'DD-MM-YYYY')}`;
    default:
      return getFullMonthAndYear(dateRange.startDate);
  }
};

export const replaceUnderscore = (toReplaceString: string): string => {
  const result = toReplaceString?.replaceAll('_', ' ');
  return result;
};

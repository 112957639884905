/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';

import { DEFAULT_VALUE } from '@constants/common';

dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(timezone);

const getFormattedDate = (
  date: Date | string,
  dateFormat = 'DD/MM/YYYY'
): string => dayjs(date)?.format(dateFormat);

const formatStringToDate = (date: string): Date => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [day, month, year] = date?.split('/');
  return new Date(
    year as unknown as number,
    (month as unknown as number) - 1,
    day as unknown as number
  );
};

const isValidDate = (dateStr: string) => {
  if (!dateStr) return false;
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;

  if (dateStr?.match(regex) === null) {
    return false;
  }

  // eslint-disable-next-line no-unsafe-optional-chaining
  const [day, month, year] = dateStr?.split('/');

  const isoFormattedStr = `${year}-${month}-${day}`;

  const date = new Date(isoFormattedStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(isoFormattedStr);
};

const checkForInvalidCharactersInDate = (dateStr: string): boolean => {
  const numberAndSlashOnlyRegex = /[^0-9\\/]/;
  const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!dateStr) return false;
  if (numberAndSlashOnlyRegex.test(dateStr)) return true;
  if (dateStr?.length < 10) return false;

  if (dateStr?.length >= 10 && !dateFormatRegex.test(dateStr)) {
    return true;
  }

  // eslint-disable-next-line no-unsafe-optional-chaining
  const [day, month, year] = dateStr?.split('/');

  const isoFormattedStr = `${year}-${month}-${day}`;

  const date = new Date(isoFormattedStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return true;
  }

  return !date.toISOString().startsWith(isoFormattedStr);
};

const getFormattedDates = (
  dates: { startDate: Date; endDate: Date },
  format = 'YYYY-MM-DD'
) => ({
  startDate: getFormattedDate(dates.startDate?.toISOString(), format),
  endDate: getFormattedDate(dates.endDate?.toISOString(), format),
});
const getFormattedUTCDates = (dates: { startDate: Date; endDate: Date }) => ({
  startDate: dates.startDate
    ? dayjs(getFormattedDate(dates.startDate, 'YYYY-MM-DDT00:00:00.000Z'))
        .toDate()
        .toISOString()
    : '',
  endDate: dates.startDate
    ? dayjs(getFormattedDate(dates.endDate, 'YYYY-MM-DDT23:59:59.999Z'))
        .toDate()
        .toISOString()
    : '',
});

const convertMinutesToTime = (totalMinutes: number) => {
  if (!totalMinutes) return DEFAULT_VALUE;
  const hours = Math.floor(totalMinutes / 60);
  const remainingMins = totalMinutes % 60;

  const paddedHour = hours.toLocaleString('en-US', { minimumIntegerDigits: 2 });
  const paddedMins = remainingMins.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  });
  const formattedUTCTime = `${paddedHour}:${paddedMins}`;
  const formattedDate = `${getFormattedDate(
    '01/01/2021',
    `YYYY-MM-DDT${formattedUTCTime}:00.000`
  )}Z`;
  const localTime = getFormattedDate(
    new Date(formattedDate).toLocaleString(),
    `h:mm A`
  );
  return localTime;
};

const getTimeZone = () => dayjs.tz.guess();

const splitTime = (timeInHours = '') => {
  const timeArray = (timeInHours || '').split(':');
  return {
    hour: Number(timeArray[0]) || 0,
    min: Number(timeArray[1]) || 0,
    sec: Number(timeArray[2]) || 0,
  };
};

// Function to convert epoch time to normal time
const convertEpochToNormalTime = (epochTime): Date => {
  const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  return new Date(d.setUTCSeconds(epochTime));
};

const getFirstAndLastDateOfMonth = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();

  const firstDate = new Date(year, month, 1);
  const lastDate = new Date(year, month + 1, 0);

  return {
    firstDate,
    lastDate,
  };
};

const getFullMonthAndYear = (date): string => {
  const options = { month: 'long', year: 'numeric' };
  return date.toLocaleString('en-US', options);
};

export {
  checkForInvalidCharactersInDate,
  convertMinutesToTime,
  formatStringToDate,
  getFormattedDate,
  getFormattedDates,
  getTimeZone,
  isValidDate,
  splitTime,
  getFormattedUTCDates,
  convertEpochToNormalTime,
  getFirstAndLastDateOfMonth,
  getFullMonthAndYear,
};

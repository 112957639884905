import { StorageKeys } from '@constants/storage';

import { ITokenResponse } from '../types/common';

const getLocalStorageItem = (key: string) => {
  const item = localStorage.getItem(key);
  try {
    return JSON.parse(item || '');
  } catch {
    return item;
  }
};

const setLocalStorageItem = (key: string, value: unknown) => {
  localStorage.setItem(
    key,
    typeof value === 'object' ? JSON.stringify(value) : `${value}`
  );
};

const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key);
};

const clearAllLocalStorageItems = () => {
  localStorage.clear();
  window.location.reload();
};

const setMultipleLocalStorageItems = (
  dataItems: Array<{ key: string; value: string }>
) => {
  dataItems.forEach((item) => {
    setLocalStorageItem(item.key, item.value);
  });
};

const setTokensInStorage = ({ accessToken, refreshToken }: ITokenResponse) => {
  setMultipleLocalStorageItems([
    { key: StorageKeys.TOKEN, value: accessToken },
    { key: StorageKeys.REFRESH_TOKEN, value: refreshToken },
  ]);
};

export {
  clearAllLocalStorageItems,
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
  setTokensInStorage,
};

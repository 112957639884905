/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { DEFAULT_VALUE, FileCategory } from '@constants/common';
import { CircularLoader, Excel, Upload } from '@icons';
import { getPresignedUrl, uploadFileToS3 } from '@services/fileServices';
import { showNotifier } from '@containers/notification/reducer';
import { useAppDispatch } from '@store/store';

import Button from '../button/Button';

interface IDragAndDropProps {
  setFileName: React.Dispatch<React.SetStateAction<string>>;
  fileCategory: FileCategory;
  handleSubmit?: () => void;
}

const DragAndDrop: React.FC<IDragAndDropProps> = (props) => {
  const { setFileName, fileCategory, handleSubmit } = props;
  const [file, setFile] = useState(null);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'file/csv': ['.csv'] },
    maxFiles: 1,
  });

  const dispatch = useAppDispatch();

  const uploadFile = async () => {
    setIsLoading(true);
    const {
      getPresignedUrl: { fileName, url },
    } = await getPresignedUrl(fileCategory);

    if (url) {
      const response = await uploadFileToS3(file, url);
      if (response?.message) {
        dispatch(
          showNotifier({
            message: 'Failed to upload!',
            type: 'error',
          })
        );
      } else {
        setFileName(fileName);
        setIsUploaded(true);
      }
    }
    setIsLoading(false);
    return fileName;
  };

  const clearFile = () => {
    setFileName('');
    setIsUploaded(false);
    setFile(null);
  };

  useEffect(() => {
    if (file) uploadFile();
  }, [file]);

  return (
    <div className='flex justify-center'>
      {!isUploaded ? (
        <div
          {...getRootProps()}
          className={`max-w-4xl w-full h-32 flex gap-5 rounded-lg p-4 justify-center items-center border-dashed border-2 border-gray-100 
    cursor-pointer ${isDragActive ? 'bg-gray-100 opacity-70' : 'bg-gray-300'} `}
        >
          {isLoading ? (
            <CircularLoader className='h-28' />
          ) : (
            <>
              <Upload className='h-20 w-20' />
              <div>
                <div className='border-dashed border px-3 py-2'>
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p>
                      Drag and drop some files here, or click to select files
                    </p>
                  )}
                </div>
                <p className='text-center mt-2 p-1'>No files uploaded</p>
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          className={`max-w-4xl w-full h-32 rounded-lg p-4 border-dashed border-2 border-gray-300 
        flex justify-center flex-col items-center bg-gray-100/70`}
        >
          <div className='flex justify-center gap-3'>
            <div className='h-10 w-10'>
              <img src={Excel} alt='fileIcon' />
            </div>
            <p className='my-2 p-1'>{`File name: ${
              file?.name || DEFAULT_VALUE
            }`}</p>
          </div>
          <div className='flex gap-3'>
            <Button
              handleButtonClick={clearFile}
              className='w-[80px] rounded px-4 text-snow bg-error/70 hover:bg-error/100'
            >
              Clear
            </Button>
            {handleSubmit && (
              <Button
                handleButtonClick={handleSubmit}
                className='w-[80px] rounded px-4 text-snow bg-info/70 hover:bg-info/100'
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DragAndDrop;

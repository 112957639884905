import { DEVICE_CATEGORY_ICONS } from '@constants/device';

import { ICellDetails } from './types';

const CellInfo = ({
  name,
  subLabel,
  category,
  type = 'text',
}: ICellDetails) => {
  switch (type) {
    case 'icon': {
      const Icon = DEVICE_CATEGORY_ICONS[category];
      return (
        <div>
          <div className='flex gap-2 items-center'>
            {Icon && <Icon />}
            {name}
          </div>
        </div>
      );
    }
    case 'text':
    default:
      return (
        <div className='py-1'>
          <p>{name}</p>
          <p className='text-gray-400'>{subLabel}</p>
        </div>
      );
  }
};

export default CellInfo;

import RoutePaths from '@routes/RoutesPath';

import {
  Devices,
  Users,
  Reports,
  Feedback,
  Notification,
  Admin,
  Warranty,
} from '@icons';
import { UserPermisssions } from '@constants/common';
import { IRoute } from './type';

export const routeLabels = {
  admins: 'Admins',
  users: 'Users',
  devices: 'Devices',
  reports: 'Reports',
  routines: 'Routines',
  geofencing: 'Geofencing',
  ambientTemperature: 'Ambient Temperature',
  hoursOfUsage: 'Hours of Usage',
  feedback: 'Feedbacks',
  notifications: 'Push Notifications',
  warranty: 'Warranty',
  filterReset: 'Filter Reset',
};

export const routes: IRoute[] = [
  {
    label: routeLabels.users,
    url: RoutePaths.USERS,
    icon: Users,
    permission: UserPermisssions.VIEW_USERS,
  },
  {
    label: routeLabels.admins,
    url: RoutePaths.ADMINS,
    icon: Admin,
    permission: UserPermisssions.VIEW_ADMINS,
  },
  {
    label: routeLabels.devices,
    url: RoutePaths.DEVICES,
    icon: Devices,
    permission: UserPermisssions.VIEW_DEVICES,
  },
  {
    label: routeLabels.feedback,
    url: RoutePaths.FEEDBACK,
    icon: Feedback,
    permission: UserPermisssions.VIEW_FEEDBACK,
  },
  {
    label: routeLabels.warranty,
    url: RoutePaths.WARRANTY,
    icon: Warranty,
    permission: UserPermisssions.VIEW_DEVICES,
  },
  {
    label: routeLabels.notifications,
    url: RoutePaths.PUSH_NOTIFICATIONS,
    icon: Notification,
    permission: UserPermisssions.VIEW_CAMPAIGNS,
  },
  {
    label: routeLabels.reports,
    url: RoutePaths.REPORTS.ROOT,
    icon: Reports,
    permission: UserPermisssions.VIEW_INSIGHTS,
    nestedLinks: [
      {
        label: routeLabels.routines,
        url: RoutePaths.REPORTS.ROUTINES,
        permission: UserPermisssions.VIEW_ROUTINES,
      },
      {
        label: routeLabels.geofencing,
        url: RoutePaths.REPORTS.GEOFENCING,
        permission: UserPermisssions.VIEW_GEOFENCES,
      },
      {
        label: routeLabels.ambientTemperature,
        url: RoutePaths.REPORTS.AVG_AMBIENT_TEMP,
        permission: UserPermisssions.VIEW_INSIGHTS,
      },
      {
        label: routeLabels.hoursOfUsage,
        url: RoutePaths.REPORTS.HOURS_OF_USAGE,
        permission: UserPermisssions.VIEW_INSIGHTS,
      },
      {
        label: routeLabels.filterReset,
        url: RoutePaths.REPORTS.FILTER_RESET,
        permission: UserPermisssions.VIEW_INSIGHTS,
      },
    ],
  },
];

import { createSlice } from '@reduxjs/toolkit';

import { CampaignFilters, CampaignOption, CampaignSlice } from '../type';

const filtersInitialState: CampaignFilters = {
  selectedStatus: null,
  pageIndex: 1,
};

export const initialState: CampaignSlice = {
  filters: {
    campaign: filtersInitialState,
  },
  totalCampaignCount: 0,
};

export const campaignSlice = createSlice({
  name: 'Campaign',
  initialState,
  reducers: {
    updatePageIndex: (state, { payload }: { payload: number }) => {
      state.filters.campaign.pageIndex = payload;
    },
    updateSelectedStatus: (state, { payload }: { payload: CampaignOption }) => {
      state.filters.campaign.selectedStatus = payload;
    },
  },
});

export const { updatePageIndex, updateSelectedStatus } = campaignSlice.actions;

export default campaignSlice.reducer;

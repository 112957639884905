import { GET_PRESIGNED_URL } from '@containers/auth/store/queries';

import { showNotifier } from '@containers/notification/reducer';
import { FileCategory } from '@constants/common';
import { client } from './api';
import { IGetPresignedUrlResponse } from './type';

const getPresignedUrl = (
  category: FileCategory = FileCategory.CAMPAIGN_USERS
): Promise<IGetPresignedUrlResponse> =>
  client.request(GET_PRESIGNED_URL, {
    category,
  });

const getDownloadUrl = (
  key: string,
  category: FileCategory = FileCategory.CAMPAIGN_USERS
): Promise<IGetPresignedUrlResponse> =>
  client.request(GET_PRESIGNED_URL, {
    category,
    urlType: 'DOWNLOAD',
    key,
  });

const uploadFileToS3 = async (file: File, url: string) => {
  try {
    const response = await fetch(url, {
      body: file,
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': file.type,
      },
      method: 'PUT',
      mode: 'cors',
    });
    return response;
  } catch (error) {
    return error;
  }
};

export { getPresignedUrl, uploadFileToS3, getDownloadUrl };

import { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Header from '@containers/header/Header';
import SideNavBar from '@containers/side-nav-bar/SideNavBar';
import { useGetProfileQuery } from '@containers/auth/store/api';
import { useAppDispatch } from '@store/store';
import { updateProfile } from '@containers/auth/store/reducer';
import { Breadcrumbs } from '@components';

import { useLazyGetAllPermissionsOfUserQuery } from '@containers/users/store/api';
import { updateUserPermissions } from '@containers/users/store/reducer';
import RoutePaths from './RoutesPath';

const PageNotFound = lazy(() => import('@pages/PageNotFound'));
const AdminsPage = lazy(() => import('@pages/AdminsPage'));
const AdminDetailsPage = lazy(() => import('@pages/AdminDetailsPage'));
const UsersPage = lazy(() => import('@pages/UsersPage'));
const UserDetailsPage = lazy(() => import('@pages/UserDetailsPage'));
const DevicesPage = lazy(() => import('@pages/DevicesPage'));
const DeviceDetailsPage = lazy(() => import('@pages/DeviceDetailsPage'));
const RoutinesPage = lazy(() => import('@pages/RoutinesPage'));
const AmbientTemperaturePage = lazy(
  () => import('@pages/AmbientTemperaturePage')
);
const GeoFencingPage = lazy(() => import('@pages/GeoFencingPage'));
const HoursOfUsagePage = lazy(() => import('@pages/HoursOfUsagePage'));
const FilterResetInsightsPage = lazy(
  () => import('@pages/FilterResetInsightsPage')
);
const FeedBacksPage = lazy(() => import('@pages/FeedBacksPage'));
const FeedBackDetailsPage = lazy(() => import('@pages/FeedBackDetailsPage'));
const PushNotificationsPage = lazy(() => import('@pages/NotificationsPage'));
const PushNotificationDetailsPage = lazy(
  () => import('@pages/NotificationDetailsPage')
);
const UserExportHistoryPage = lazy(
  () => import('@pages/UserExportHistoryPage')
);

const DeviceExportHistoryPage = lazy(
  () => import('@pages/DeviceExportHistoryPage')
);

const FeedbackExportHistoryPage = lazy(
  () => import('@pages/FeedbackExportHistoryPage')
);

const WarrantyPage = lazy(() => import('@pages/WarrantyPage'));
const WarrantyDetailsPage = lazy(() => import('@pages/WarrantyDetailsPage'));
const WarrantyImportPage = lazy(() => import('@pages/WarrantyImportPage'));
const WarrantyHistoryPage = lazy(() => import('@pages/WarrantyHistoryPage'));
const AmbientTemperatureExportHistoryPage = lazy(
  () => import('@pages/AmbientTemperatureExportHistoryPage')
);
const UsagePatternExportHistoryPage = lazy(
  () => import('@pages/UsagePatternExportHistoryPage')
);

const PrivateLayout = () => {
  const [isBarExpanded, setBarExpanded] = useState(true);

  const dispatch = useAppDispatch();

  const { data: profileData } = useGetProfileQuery();
  const [getAllPermissionsOfUsers, { data: userPermissionsData }] =
    useLazyGetAllPermissionsOfUserQuery();

  useEffect(() => {
    getAllPermissionsOfUsers();
  }, []);

  useEffect(() => {
    if (userPermissionsData?.getAllPermissionsOfUser) {
      dispatch(
        updateUserPermissions(userPermissionsData?.getAllPermissionsOfUser)
      );
    }
  }, [userPermissionsData?.getAllPermissionsOfUser]);

  useEffect(() => {
    if (profileData) {
      dispatch(
        updateProfile({
          ...profileData.getProfile,
        })
      );
    }
  }, [profileData]);

  const toggleSideNavBar = () => setBarExpanded(!isBarExpanded);

  return (
    <>
      <SideNavBar isBarExpanded={isBarExpanded} />
      <div
        className={`${
          isBarExpanded
            ? 'ml-[var(--expanded-sidenav-width)] w-[calc(100vw-var(--expanded-sidenav-width))]'
            : 'ml-[var(--collapsed-sidenav-width)] w-[calc(100vw-var(--collapsed-sidenav-width))]'
        } h-screen transition-all duration-[var(--sidenav-animation-duration)] motion-reduce:transition-none`}
      >
        <Header
          isBarExpanded={isBarExpanded}
          toggleSideNavBar={toggleSideNavBar}
        />
        <div className='page mt-[80px]' id='main-container'>
          <div>
            <Breadcrumbs />
          </div>
          <Suspense>
            <Routes>
              <Route
                path={RoutePaths.LOGIN}
                element={<Navigate to={RoutePaths.USERS} replace />}
              />
              <Route path={RoutePaths.ADMINS} element={<AdminsPage />} />
              <Route
                path={RoutePaths.ADMIN_DETAILS}
                element={<AdminDetailsPage />}
              />
              <Route
                path={RoutePaths.ADMINS_CREATE}
                element={<AdminDetailsPage />}
              />
              <Route path={RoutePaths.USERS} element={<UsersPage />} />
              <Route path={RoutePaths.DEVICES} element={<DevicesPage />} />
              <Route
                path={RoutePaths.REPORTS.ROOT}
                element={<Navigate to={RoutePaths.REPORTS.ROUTINES} replace />}
              />
              <Route
                path={RoutePaths.REPORTS.ROUTINES}
                element={<RoutinesPage />}
              />
              <Route
                path={RoutePaths.REPORTS.AVG_AMBIENT_TEMP}
                element={<AmbientTemperaturePage />}
              />
              <Route
                path={RoutePaths.REPORTS.GEOFENCING}
                element={<GeoFencingPage />}
              />
              <Route
                path={RoutePaths.REPORTS.HOURS_OF_USAGE}
                element={<HoursOfUsagePage />}
              />
              <Route
                path={RoutePaths.REPORTS.FILTER_RESET}
                element={<FilterResetInsightsPage />}
              />
              <Route
                path={RoutePaths.HOME}
                element={<Navigate to={RoutePaths.USERS} replace />}
              />
              <Route
                path={RoutePaths.USER_DETAILS}
                element={<UserDetailsPage />}
              />
              <Route
                path={RoutePaths.USER_EXPORT_HISTORY}
                element={<UserExportHistoryPage />}
              />
              <Route
                path={RoutePaths.DEVICE_DETAILS}
                element={<DeviceDetailsPage />}
              />
              <Route
                path={RoutePaths.USER_DEVICE_DETAILS}
                element={<DeviceDetailsPage />}
              />
              <Route
                path={RoutePaths.AMBIENT_TEMPERATURE_EXPORT_HISTORY}
                element={<AmbientTemperatureExportHistoryPage />}
              />
              <Route
                path={RoutePaths.USAGE_PATTERN_EXPORT_HISTORY}
                element={<UsagePatternExportHistoryPage />}
              />
              <Route
                path={RoutePaths.DEVICE_EXPORT_HISTORY}
                element={<DeviceExportHistoryPage />}
              />
              <Route path={RoutePaths.FEEDBACK} element={<FeedBacksPage />} />
              <Route
                path={RoutePaths.FEEDBACK_DETAILS}
                element={<FeedBackDetailsPage />}
              />
              <Route
                path={RoutePaths.FEEDBACK_EXPORT_HISTORY}
                element={<FeedbackExportHistoryPage />}
              />
              <Route path={RoutePaths.WARRANTY} element={<WarrantyPage />} />
              <Route
                path={RoutePaths.WARRANTY_DETAILS}
                element={<WarrantyDetailsPage />}
              />
              <Route
                path={RoutePaths.WARRANTY_IMPORT}
                element={<WarrantyImportPage />}
              />
              <Route
                path={RoutePaths.WARRANTY_HISTORY}
                element={<WarrantyHistoryPage />}
              />
              <Route
                path={RoutePaths.PUSH_NOTIFICATIONS}
                element={<PushNotificationsPage />}
              />
              <Route
                path={RoutePaths.PUSH_NOTIFICATION_DETAILS}
                element={<PushNotificationDetailsPage />}
              />
              <Route
                path={RoutePaths.PUSH_NOTIFICATION_CREATE}
                element={<PushNotificationDetailsPage />}
              />
              <Route path={RoutePaths.ALL} element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default PrivateLayout;

import React, { useState } from 'react';

const ReadMore = ({
  text,
  length,
}: {
  text: string;
  length: number;
}): JSX.Element => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className='text'>
      {isReadMore ? text?.slice(0, length) : text}
      {text?.length > length && (
        <button
          onClick={toggleReadMore}
          className='cursor-pointer font-light text-xs px-2 opacity-70 text-jaguar'
          type='button'
        >
          {isReadMore ? '...  show more' : ' show less'}
        </button>
      )}
    </p>
  );
};

export default ReadMore;

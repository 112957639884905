import React from 'react';
import {
  Codes,
  Command,
  Control,
  deviceControls,
  IControlItem,
  modeIcons,
} from '@containers/routines/type';
import { formatWithUnit } from '@utils/formatting';

const mainControlsForFan = [Codes.Switch, Codes.Light];
const lightControlsForFan = [Codes.BrightValue, Codes.TempValue];
const fanControlsForFan = [Codes.FanSpeed, Codes.FanDirection, Codes.Mode];
const mainControlsForAC = [Codes.Pow];
const availableACControls = [Codes.Speed, Codes.Temperature];
const swingControlsForAC = [Codes.SwingLr, Codes.SwingUd];

const genericCommandValues = {
  true: 'on',
  false: 'off',
};

const formatCommands = (commands: Command[]) => {
  const mainControls = [];
  const lightControls = [];
  const fanControls = [];
  const ACControls = [];
  const ACSwingControls = [];
  const otherControls = [];

  commands.forEach((command) => {
    const { code, value } = command || {};
    const { name, unit } = deviceControls[code] || { name: code, unit: '' };
    const controlValue = genericCommandValues[value?.toString()] || value;
    const control = { code, name, value: controlValue, unit };
    if (lightControlsForFan.includes(command.code)) lightControls.push(control);
    else if (fanControlsForFan.includes(command.code))
      fanControls.push(control);
    else if (
      [...mainControlsForFan, ...mainControlsForAC].includes(command.code)
    )
      mainControls.push(control);
    else if (availableACControls.includes(command.code))
      ACControls.push(control);
    else if (swingControlsForAC.includes(command.code))
      ACSwingControls.push(control);
    else otherControls.push(control);
  });

  return {
    mainControls,
    lightControls,
    fanControls,
    ACControls,
    ACSwingControls,
    otherControls,
  };
};

const renderCommand = ({ name, value, code, unit }: IControlItem) => (
  <div
    key={code}
    className='bg-gray-200 py-1 px-5 my-4 -ml-2 rounded-lg min-w-max capitalize cursor-default
         grid place-content-center last:mr-2'
  >
    <p className='flex justify-center items-center gap-2'>
      {name} :
      {modeIcons[value] && (
        <img
          src={modeIcons[value]}
          alt='mode'
          className={`w-5 h-5 my-2 ${
            code === Codes.SwingLr ? '-rotate-90' : ''
          }`}
        />
      )}
      <span>{formatWithUnit(value, unit)}</span>
    </p>
  </div>
);

const renderGroupedControls = (
  controlItem: IControlItem,
  controls: IControlItem[]
) => {
  const { name, value, code } = controlItem;
  if (['on', 'show'].includes(value)) {
    return (
      <div
        key={code}
        className='bg-gray-200 py-1 px-5 my-4 -ml-2 rounded-lg min-w-max capitalize cursor-default
                 grid place-content-center last:mr-2'
      >
        <p className='flex justify-center items-center gap-3'>
          {name} :
          {controls.map((item) => (
            <div
              key={item.code}
              className='border bg-snow px-3 py-1.5 rounded-lg'
            >{`${item.name} : ${formatWithUnit(item.value, item.unit)}`}</div>
          ))}
        </p>
      </div>
    );
  }
  return renderCommand(controlItem);
};

const renderGroupedLabelControls = (controls: IControlItem[], label = '') =>
  !!controls.length &&
  renderGroupedControls({ name: label, code: label, value: 'show' }, controls);

const renderMainControls = (
  mainControls: IControlItem[],
  lightControls: IControlItem[]
) =>
  mainControls.map((item) => {
    if (item.code === Codes.Light)
      return renderGroupedControls(item, lightControls);
    return renderCommand(item);
  });

const ControlRow = ({ controlActions }: { controlActions: Control }) => {

  if (!controlActions || !Object.keys(controlActions)?.length) return null;

  const {
    mainControls,
    lightControls,
    fanControls,
    ACControls,
    ACSwingControls,
    otherControls,
  } = formatCommands(controlActions?.commands);

  return (
    <div className='flex gap-5'>
      <div className='min-h-full bg-gray-400 w-1' />
      {/* Switch, Light <Brightness, Temperature>, Power */}
      {renderMainControls(mainControls, lightControls)}
      {/* Fan Speed, Fan Direction, Mode */}
      {renderGroupedLabelControls(fanControls, 'Fan')}
      {/* Speed, Temperature */}
      {ACControls.map((item) => renderCommand(item))}
      {/* Swing Left/Right, Swing Up/Down */}
      {renderGroupedLabelControls(ACSwingControls, 'Swing')}
      {/* Other controls, if any */}
      {otherControls.map((item) => renderCommand(item))}
    </div>
  );
};

export default ControlRow;
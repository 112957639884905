import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RightArrow } from '@icons';
import { RootState } from '@store/store';
import { IPageDescription } from '@containers/header/type';

const Breadcrumbs: React.FC = () => {
  const navigate = useNavigate();
  const { pages } = useSelector(
    (state: RootState) => state.rootReducer.Breadcrumbs
  );

  const onRedirect = (pageDescription: IPageDescription) => {
    const { path } = pageDescription;
    if (path) navigate(pageDescription.path);
  };

  return (
    <div className='h-full flex mt-[13px] mb-[10px] text-sm' id='breadcrumb'>
      {pages.map((page, index) => (
        <div key={page.pageName} className='flex flex-row items-center'>
          <span
            className={`cursor-default max-w-sm truncate text-[#6B7280] ${
              index === pages.length - 1
                ? ''
                : 'hover:cursor-pointer hover:text-jaguar'
            }`}
            role='presentation'
            onClick={() => onRedirect(page)}
          >
            {page.pageName}
          </span>
          {index !== pages.length - 1 && <RightArrow className='mx-[23px]' />}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumbs;

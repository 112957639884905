import { createSlice } from '@reduxjs/toolkit';
import { OptionType } from 'components/dropdown/types';

import { FeedbackFilters, FeedbackSlice, StatusOptionType } from '../type';

const filtersInitialState: FeedbackFilters = {
  selectedStatus: null,
  pageIndex: 1,
  selectedUser: null,
  userSearchText: '',
  userPage: 1,
  selectedTopic: null,
  selectedCountry: null,
};

export const initialState: FeedbackSlice = {
  filters: {
    feedback: filtersInitialState,
  },
  totalFeedbackCount: 0,
};

export const feedbackSlice = createSlice({
  name: 'Feedback',
  initialState,
  reducers: {
    updatePageIndex: (state, { payload }: { payload: number }) => {
      state.filters.feedback.pageIndex = payload;
    },
    updateSelectedStatus: (
      state,
      { payload }: { payload: StatusOptionType }
    ) => {
      state.filters.feedback.selectedStatus = payload;
    },
    updateSelectedTopic: (state, { payload }: { payload: OptionType }) => {
      state.filters.feedback.selectedTopic = payload;
    },
    updateSelectedUser: (state, { payload }: { payload: OptionType }) => {
      state.filters.feedback.selectedUser = payload;
    },
    updateUserSearchText: (state, { payload }: { payload: string }) => {
      state.filters.feedback.userSearchText = payload;
    },
    updateUserPage: (state, { payload }: { payload: number }) => {
      state.filters.feedback.userPage = payload;
    },
    updateSelectedCountry: (state, { payload }: { payload: OptionType }) => {
      state.filters.feedback.selectedCountry = payload;
    },
  },
});

export const {
  updatePageIndex,
  updateSelectedTopic,
  updateSelectedStatus,
  updateSelectedUser,
  updateUserPage,
  updateUserSearchText,
  updateSelectedCountry,
} = feedbackSlice.actions;

export default feedbackSlice.reducer;

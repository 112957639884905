import { DEVICE_CATEGORY_KEYS } from '@constants/device';
import {
  DateRange,
  IDeviceCategory,
  IPaginationInput,
  IRoom,
  IUser,
} from '@customTypes/common';
import {
  Auto,
  Cool,
  Dry,
  Fan,
  Forward,
  Natural,
  NewHigh,
  NewLow,
  NewMedium,
  Quiet,
  Reverse,
  Sleep,
  Turbo,
  Ud1,
  Ud2,
  Ud3,
  Ud4,
  Ud5,
} from '@icons';
import { DisplayValueType, IColumn } from 'components/datatable/types';

export enum Codes {
  BrightValue = 'bright_value',
  FanDirection = 'fan_direction',
  FanSpeed = 'fan_speed',
  Light = 'light',
  Mode = 'mode',
  Pow = 'pow',
  Speed = 'speed',
  SwingLr = 'swingLr',
  SwingUd = 'swingUd',
  Switch = 'switch',
  TempValue = 'temp_value',
  Temperature = 'temperature',
}

export const deviceControls = {
  [Codes.BrightValue]: { name: 'Brightness', unit: '%' },
  [Codes.FanDirection]: { name: 'Direction', unit: '' },
  [Codes.FanSpeed]: { name: 'Speed', unit: '' },
  [Codes.Light]: { name: 'Light', unit: '' },
  [Codes.Mode]: { name: 'Mode', unit: '' },
  [Codes.Pow]: { name: 'Power', unit: '' },
  [Codes.Speed]: { name: 'Speed', unit: '' },
  [Codes.SwingLr]: { name: 'Left / Right', unit: '' },
  [Codes.SwingUd]: { name: 'Up / Down', unit: '' },
  [Codes.Switch]: { name: 'Switch', unit: '' },
  [Codes.TempValue]: { name: 'Temperature', unit: '%' },
  [Codes.Temperature]: { name: 'Temperature', unit: '°C' },
};

enum AcModes {
  'cool' = 'cool',
  'dry' = 'dry',
  'fan' = 'fan',
  'auto' = 'auto',
}

enum AcFanModes {
  's1' = 's1',
  's2' = 's2',
  's3' = 's3',
  's4' = 's4',
  's5' = 's5',
  'auto' = 'auto',
}

enum Directions {
  'reverse' = 'reverse',
  'forward' = 'forward',
}

enum FanTimer {
  'cancel' = 'cancel',
  '1h' = '1h',
  '2h' = '2h',
  '4h' = '4h',
  '8h' = '8h',
}

enum FanMode {
  'normal' = 'normal',
  'natural' = 'natural',
  'sleep' = 'sleep',
}

enum SwingAngles {
  'a1' = 'a1',
  'a2' = 'a2',
  'a3' = 'a3',
  'a4' = 'a4',
  'a5' = 'a5',
}

export interface Command {
  code: Codes;
  value:
    | 'true'
    | 'false'
    | AcModes
    | AcFanModes
    | FanMode
    | FanTimer
    | SwingAngles
    | 'natural'
    | 'off'
    | 'on'
    | number
    | null;
}

export const modeIcons = {
  [AcFanModes.auto]: Auto,
  [AcFanModes.s1]: Quiet,
  [AcFanModes.s2]: NewLow,
  [AcFanModes.s3]: NewMedium,
  [AcFanModes.s4]: NewHigh,
  [AcFanModes.s5]: Turbo,
  [AcModes.auto]: Auto,
  [AcModes.cool]: Cool,
  [AcModes.dry]: Dry,
  [AcModes.fan]: Fan,
  [Directions.reverse]: Reverse,
  [Directions.forward]: Forward,
  [FanMode.natural]: Natural,
  [FanMode.sleep]: Sleep,
  [SwingAngles.a1]: Ud1,
  [SwingAngles.a2]: Ud2,
  [SwingAngles.a3]: Ud3,
  [SwingAngles.a4]: Ud4,
  [SwingAngles.a5]: Ud5,
};

export interface Control {
  commands: Command[];
}

export interface Device {
  deviceId: string;
  deviceName: string;
  room: IRoom;
}

export enum RoutineType {
  MANUAL_ROUTINE = 'MANUAL_ROUTINE',
  SMART_ROUTINE = 'SMART_ROUTINE',
}

export enum RoutineStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',
  INACTIVE = 'INACTIVE',
}

export enum RoutineClassName {
  Routine = 'Routine',
  ParentRoutine = 'ParentRoutine',
  ChildRoutine = 'ChildRoutine'
}

export interface IChildRoutine {
  id: string;
  startTime: string;
  endTime: string;
  status: RoutineStatus;
  control: Control
  endControl: Control
}

export interface IRoutine {
  control: Control;
  createdAt: string;
  deviceUser: Device;
  device: { category: IDeviceCategory };
  endTime?: Date;
  id: string;
  repeat: boolean;
  startTime: number;
  status: RoutineStatus;
  type: RoutineType;
  user: IUser;
  className: RoutineClassName;
  childRoutines?: IChildRoutine[];
}

export interface GetRoutinesForAdmin {
  nodes: IRoutine[];
  totalCount: number;
}

export interface IGetRoutinesResponse {
  getRoutinesForAdmin: GetRoutinesForAdmin;
}

export interface ListRoutinesInput {
  category?: DEVICE_CATEGORY_KEYS;
  dateRange?: DateRange;
  deviceId?: string;
  paginationInput: IPaginationInput;
  roomId?: string;
  status?: RoutineStatus;
  type?: RoutineType;
  userId?: string;
  shouldIncludeChild?: boolean;
}

export interface IGetRoutinesRequest {
  listRoutinesInput: ListRoutinesInput;
}

export type IControlItem = {
  name: string;
  value: string;
  code: string;
  unit?: string;
};

export interface ChildRoutineRowProps {
  childRoutine: IChildRoutine;
  columns: IColumn<IChildRoutine, DisplayValueType>[]
}

export type ChildRoutineRow = (props: ChildRoutineRowProps) => React.ReactElement

import { createSlice } from '@reduxjs/toolkit';
import { OptionType } from 'components/dropdown/types';

const filtersInitialState = {
  pageIndex: 1,
  userPage: 1,
  userSearchText: '',
  warrantyDeviceSearchText: '',
  selectedUser: null,
  selectedVendor: null,
};

export const initialState = {
  filters: {
    warrantyDevice: filtersInitialState,
  },
  totalDeviceCount: 0,
};

export const warrantySlice = createSlice({
  name: 'Warranty',
  initialState,
  reducers: {
    updatePageIndex: (state, { payload }: { payload: number }) => {
      state.filters.warrantyDevice.pageIndex = payload;
    },
    updateWarrantyDeviceSearchText: (
      state,
      { payload }: { payload: string }
    ) => {
      state.filters.warrantyDevice.warrantyDeviceSearchText = payload;
    },
    updateSelectedUser: (state, { payload }: { payload: OptionType }) => {
      state.filters.warrantyDevice.selectedUser = payload;
    },
    updateUserSearchText: (state, { payload }: { payload: string }) => {
      state.filters.warrantyDevice.userSearchText = payload;
    },
    updateSelectedVendor: (state, { payload }: { payload: OptionType }) => {
      state.filters.warrantyDevice.selectedVendor = payload;
    },
    updateUserPage: (state, { payload }: { payload: number }) => {
      state.filters.warrantyDevice.userPage = payload;
    },
    updateTotalDeviceCount: (state, { payload }) => {
      state.totalDeviceCount = payload;
    },
  },
});

export const {
  updatePageIndex,
  updateWarrantyDeviceSearchText,
  updateSelectedUser,
  updateUserPage,
  updateUserSearchText,
  updateTotalDeviceCount,
  updateSelectedVendor,
} = warrantySlice.actions;

export default warrantySlice.reducer;

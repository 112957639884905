import api from '@services/api';

import {
  IDeleteUserRequest,
  IDeleteUserResponse,
  IGetAllPermissionsOfUserResponse,
  IGetUserByIDRequest,
  IGetUserByIDResponse,
  IGetUsersRequest,
  IGetUsersResponse,
  IUpdateCustomerRequest,
  IUpdateCustomerResponse,
} from '../type';
import {
  DELETE_USER,
  GET_ALL_PERMISSIONS_OF_USER,
  GET_USERS,
  GET_USERS_FILTER,
  GET_USER_BY_ID,
  UPDATE_CUSTOMER,
} from './queries';

const UsersApi = api
  .enhanceEndpoints({
    addTagTypes: ['Users'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUsers: builder.query<IGetUsersResponse, IGetUsersRequest>({
        query: ({ isFilter, ...variables }) => ({
          document: isFilter ? GET_USERS_FILTER : GET_USERS,
          variables,
        }),
        transformResponse: (response: IGetUsersResponse) => response,
        providesTags: ['Users'],
      }),
      getUserByID: builder.query<IGetUserByIDResponse, IGetUserByIDRequest>({
        query: (variables) => ({
          document: GET_USER_BY_ID,
          variables,
        }),
        transformResponse: (response: IGetUserByIDResponse) => response,
      }),
      updateCustomer: builder.mutation<
        IUpdateCustomerResponse,
        IUpdateCustomerRequest
      >({
        query: (variables) => ({
          document: UPDATE_CUSTOMER,
          variables,
        }),
        invalidatesTags: ['Users'],
      }),
      deleteUser: builder.mutation<IDeleteUserResponse, IDeleteUserRequest>({
        query: (variables) => ({
          document: DELETE_USER,
          variables,
        }),
        invalidatesTags: ['Users'],
      }),
      getAllPermissionsOfUser: builder.query<
        IGetAllPermissionsOfUserResponse,
        void
      >({
        query: (variables) => ({
          document: GET_ALL_PERMISSIONS_OF_USER,
          variables,
        }),
        transformResponse: (response: IGetAllPermissionsOfUserResponse) =>
          response,
        providesTags: ['Users'],
      }),
    }),
  });

export const {
  useDeleteUserMutation,
  useLazyGetUserByIDQuery,
  useLazyGetUsersQuery,
  useUpdateCustomerMutation,
  useLazyGetAllPermissionsOfUserQuery,
} = UsersApi;

import React from 'react';
import { SomethingWrong } from '@icons';

import { IErrorTabProps } from './type';

const ErrorTab = (props: IErrorTabProps) => {
  const { title } = props;

  return (
    <div>
      <div className='text-xl font-semibold text-jaguar'>{title}</div>
      <div className='flex items-center justify-center flex-col'>
        <SomethingWrong className='w-80 h-80 mt-5' />
        <span className='block -mt-12 text-jaguar'>Something went wrong !</span>
      </div>
    </div>
  );
};

export default ErrorTab;

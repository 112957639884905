import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-moment';

Chart.register(...registerables);

const LineGraph = ({ dataset, chartOptions }) => (
  <Line data={dataset} options={chartOptions} />
);

export default LineGraph;

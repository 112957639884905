import api from '@services/api';

import { GET_PROFILE, LOGIN, LOGOUT, RESET_PASSWORD } from './queries';
import {
  IGetProfileResponse,
  ILoginRequest,
  ILoginResponse,
  ILogoutRequest,
  ILogoutResponse,
  IResetPasswordRequest,
  IResetPasswordResponse,
} from '../type';

const AuthApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: (variables) => ({
        document: LOGIN,
        variables,
      }),
      transformResponse: (response: ILoginResponse) => response,
    }),
    getProfile: builder.query<IGetProfileResponse, void>({
      query: () => ({
        document: GET_PROFILE,
      }),
      transformResponse: (response: IGetProfileResponse) => response,
    }),
    resetPassword: builder.mutation<
      IResetPasswordResponse,
      IResetPasswordRequest
    >({
      query: (variables) => ({
        document: RESET_PASSWORD,
        variables,
      }),
      transformResponse: (response: IResetPasswordResponse) => response,
    }),
    logout: builder.mutation<ILogoutResponse, ILogoutRequest>({
      query: (variables) => ({
        document: LOGOUT,
        variables,
      }),
      transformResponse: (response: ILogoutResponse) => response,
    }),
  }),
});

export const {
  useLoginMutation,
  useGetProfileQuery,
  useLogoutMutation,
  useResetPasswordMutation,
} = AuthApi;

import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Logout, PrismLogo } from '@icons';
import { RootState, useAppDispatch } from '@store/store';
import { clearAllLocalStorageItems, getLocalStorageItem } from '@utils/storage';
import { Modal } from '@components';
import { StorageKeys } from '@constants/storage';
import RoutePaths from '@routes/RoutesPath';

import { IPopupButton } from '../../components/modal/types';
import { useLogoutMutation } from '../auth/store/api';
import { clearProfile } from '../auth/store/reducer';

import { routes } from './routes';
import { ISideNavBarProps } from './type';
import SideNavLink from './components/SideNavLink';

const SideNavBar = (props: ISideNavBarProps) => {
  const { isBarExpanded } = props;

  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { pages } = useSelector(
    (state: RootState) => state.rootReducer.Breadcrumbs
  );

  const userPermissions = useSelector(
    (state: RootState) => state.rootReducer.Users.userPermissions
  );

  const checkIsActivePath = (pathToCheck: string, currentPath: string) => {
    const parentPath = pages[0]?.path || currentPath; // TO DO - To be kept until all paths are brought under breadcrumbs
    return parentPath.startsWith(pathToCheck);
  };

  const [logout, { isLoading }] = useLogoutMutation();

  const onLogout = async () => {
    const response = await logout({
      refreshToken: getLocalStorageItem(StorageKeys.REFRESH_TOKEN),
    }).unwrap();
    if (response.logout) {
      dispatch(clearProfile());
      clearAllLocalStorageItems();
    }
  };

  const handlePopupActions = async (button: IPopupButton) => {
    if (button && button.text === 'Confirm') {
      await onLogout();
    }
    setLogoutModalOpen(false);
  };

  const showLogoutModal = () => setLogoutModalOpen(true);

  return (
    <nav
      className={`${
        isBarExpanded
          ? 'w-[var(--expanded-sidenav-width)]'
          : 'w-[var(--collapsed-sidenav-width)]'
      } bg-jaguar h-screen float-left 
      border-r border-r-grey-border transition-all duration-[var(--sidenav-animation-duration)] 
      motion-reduce:transition-none fixed top-0 left-0`}
    >
      <div className='flex items-center border-b-2 border-b-black justify-center w-full h-20'>
        {isBarExpanded && (
          <Link to={RoutePaths.HOME}>
            <PrismLogo />
          </Link>
        )}
      </div>
      <div className='py-[32px] px-4'>
        {routes.map(({ label, nestedLinks, url, icon, permission }) => {
          const isActiveRootPath = checkIsActivePath(url, pathname);
          const hasNestedLinks = !!nestedLinks?.length;
          if (userPermissions?.includes(permission))
            return (
              <div className='mb-3' key={url}>
                <SideNavLink
                  url={url}
                  name={label}
                  icon={icon}
                  isActive={isActiveRootPath}
                  showExpandedView={isBarExpanded}
                  hasNestedLinks={hasNestedLinks}
                />
                {!!hasNestedLinks && isBarExpanded && isActiveRootPath && (
                  <div className='flex flex-col gap-1 pl-4 mt-4 ml-[22px] border-l border-l-grey-border'>
                    {nestedLinks?.map(
                      ({
                        label: nestedLinkLabel,
                        url: nestedLinkUrl,
                        permission: nestedLinkPermission,
                      }) =>
                        userPermissions?.includes(nestedLinkPermission) && (
                          <SideNavLink
                            url={nestedLinkUrl}
                            name={nestedLinkLabel}
                            isActive={checkIsActivePath(
                              nestedLinkUrl,
                              pathname
                            )}
                            key={nestedLinkUrl}
                            isNestedLink
                          />
                        )
                    )}
                  </div>
                )}
              </div>
            );
          return null;
        })}
      </div>
      <div
        className='absolute right-0 bottom-0 w-full h-[68px] items-center flex pl-[30px] cursor-pointer border-t-2 border-t-black'
        onClick={showLogoutModal}
        role='presentation'
      >
        <Logout />
        {isBarExpanded && (
          <span className='text-snow text-base px-[14px]'>Logout</span>
        )}
      </div>
      <Modal
        isOpen={isLogoutModalOpen}
        buttons={[
          { text: 'Cancel', type: 'default' },
          {
            text: 'Confirm',
            type: 'primary',
            disabled: isLoading,
            isLoading,
          },
        ]}
        title='Log Out'
        onClose={handlePopupActions}
        description='Are you sure you want to log out?'
        isLoading={isLoading}
      />
    </nav>
  );
};

export default SideNavBar;

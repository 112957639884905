const RoutePaths = {
  ALL: '*',
  HOME: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  ADMINS: '/admins',
  ADMINS_CREATE: '/admins/create',
  ADMIN_DETAILS: '/admins/:id',
  USERS: '/users',
  USER_DETAILS: '/users/:id',
  USER_EXPORT_HISTORY: '/users/export-history',
  DEVICES: '/devices',
  DEVICE_DETAILS: '/devices/:id',
  DEVICE_EXPORT_HISTORY: '/devices/export-history',
  USER_DEVICE_DETAILS: '/users/:userId/devices/:id',
  FEEDBACK: '/feedbacks',
  FEEDBACK_DETAILS: '/feedbacks/:id',
  FEEDBACK_EXPORT_HISTORY: '/feedbacks/export-history',
  WARRANTY: '/warranty',
  WARRANTY_DETAILS: '/warranty/:id',
  WARRANTY_IMPORT: '/warranty/import',
  WARRANTY_HISTORY: '/warranty/history',
  PUSH_NOTIFICATIONS: '/notifications',
  PUSH_NOTIFICATION_DETAILS: '/notifications/:id',
  PUSH_NOTIFICATION_CREATE: '/notifications/create',
  AMBIENT_TEMPERATURE_EXPORT_HISTORY:
    '/user-devices/ambient-temp-report/export-history',
  USAGE_PATTERN_EXPORT_HISTORY: '/user-devices/usage-pattern/export-history',
  REPORTS: {
    ROOT: '/reports',
    ROUTINES: '/reports/routines',
    GEOFENCING: '/reports/geofencing',
    AVG_AMBIENT_TEMP: '/reports/average-ambient-temperature',
    HOURS_OF_USAGE: '/reports/hours-of-usage',
    FILTER_RESET: '/reports/filter-reset',
  },
};

export default RoutePaths;

const months: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

enum DateType {
  DAYS = 'Days',
  DAY = 'Day',
  MONTHS = 'Months',
  MONTH = 'Month',
  YEARS = 'Years',
  YEAR = 'Year',
  HOURS = 'Hours',
  HOUR = 'Hour',
  MINUTES = 'Minutes',
  MINUTE = 'Minute',
  SECONDS = 'Seconds',
  SECOND = 'Second',
}

enum GraphTimeDivisionType {
  day = 'day',
  month = 'month',
  year = 'year',
}

export { months, DateType, GraphTimeDivisionType };

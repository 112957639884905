/* eslint-disable no-use-before-define */
import { FunctionComponent, SVGProps } from 'react';

export type SVGComponent = FunctionComponent<SVGProps<SVGSVGElement>>;

export type ITokenResponse = {
  accessToken: string;
  refreshToken: string;
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type IPaginationInput = {
  limit: number;
  offset: number;
  sort?: string;
  order?: SortOrder;
};

export type Country = {
  countryName?: string;
  countryISOCode?: string;
};

export type IUser = {
  id: string;
  email?: string;
  firstName: string;
  lastName: string;
  phone?: string;
  isOnBoarded?: boolean;
  lastActiveAt?: Date;
  createdAt?: Date;
  country?: Country;
};

export enum IDeviceCategory {
  CEILING_FAN,
  AC,
  AIR_PURIFIER,
  DOOR_LOCK,
}

export enum IDeviceAction {
  FILTER_RESET,
}

export enum DeviceVendor {
  TUYA = 'TUYA',
  GREE = 'GREE',
}

export enum WarrantyType {
  INDOOR = 'INDOOR',
  OUTDOOR = 'OUTDOOR',
  EXTENDED = 'EXTENDED',
  EXTENDED_INDOOR = 'EXTENDED_INDOOR',
  EXTENDED_OUTDOOR = 'EXTENDED_OUTDOOR',
}

export type WarrantyConfig = {
  type?: WarrantyType;
};

export type Warranty = {
  id?: string;
  startedOn?: Date;
  expiredOn?: Date;
  warrantyConfig?: WarrantyConfig;
};

export type IDeviceUser = {
  id: string;
  accessType?: string;
  device?: IDevice;
  deviceId: string;
  deviceName: string;
  room?: IRoom;
  user?: IUser;
  createdAt?: Date;
};

export type IVendor = {
  id: string;
  name: string;
};

export type IDeviceInfo = {
  online: boolean;
};

export type IInvitation = {
  id: string;
  invitedUser?: IUser;
  status: string;
};

export type IRoom = {
  id: string;
  name: string;
};

export type IDeviceMeta = {
  id: string;
  lastFilterResetDate?: Date;
};

export type IDevice = {
  id: string;
  deviceUsers?: [IDeviceUser];
  physicalDeviceId: string;
  rooms: [IRoom];
  latitude: string;
  longitude: string;
  vendor: IVendor;
  vendorId: string;
  deviceInfo?: IDeviceInfo;
  category: IDeviceCategory;
  modelName: string;
  averageAmbientTemperature?: number;
  stateChangedAt?: Date;
  state?: string;
  warranties?: Warranty[];
  deviceMeta?: IDeviceMeta;
  createdAt?: Date;
};

export enum InputTypes {
  text = 'text',
  area = 'area',
  password = 'password',
  number = 'number',
  checkbox = 'checkbox',
  phoneNumber = 'phoneNumber',
}

export type DateRange = {
  startDate: string;
  endDate: string;
};

export type Option = {
  key: string;
  label: string;
  value: string;
};

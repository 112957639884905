/* eslint-disable prefer-regex-literals */

export const nonNumberRegExp = new RegExp(/[^+\0-9]/g);

export const emailRegex = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const phoneNoRegex = new RegExp(/^\+91[\s][0-9]+$/);
export const positiveNumberRegex = new RegExp(/^[+]?((\d+(\.\d*)?)|(\.\d+))$/);

export const onlyNumber = new RegExp(/^[0-9]+$/);

export enum Actions {
  DELETE = 'Delete',
  VIEW = 'View',
}

export const DEFAULT_VALUE = '---';

export const disabledRoomMessage = 'Please select a user first';

export enum FileCategory {
  FEEDBACK_MEDIA = 'FEEDBACK_MEDIA',
  CAMPAIGN_USERS = 'CAMPAIGN_USERS',
  EXPORT_USER = 'EXPORT_USER',
  EXPORT_DEVICE = 'EXPORT_DEVICE',
  EXPORT_FEEDBACK = 'EXPORT_FEEDBACK',
  IMPORT_WARRANTY = 'IMPORT_WARRANTY',
  EXPORT_WARRANTY = 'EXPORT_WARRANTY',
  EXPORT_AMBIENT_TEMPERATURE_REPORT = 'EXPORT_AMBIENT_TEMPERATURE_REPORT',
  EXPORT_USAGE_PATTERN = 'EXPORT_USAGE_PATTERN',
}

export enum UrlActions {
  UPLOAD = 'UPLOAD',
  DOWNLOAD = 'DOWNLOAD',
}

export enum DEVICE_ACTION {
  FILTER_RESET = 'FILTER_RESET',
}

export enum UserPermisssions {
  VIEW_USERS = 'view-users',
  VIEW_ADMINS = 'view-admins',
  VIEW_DEVICES = 'view-devices',
  VIEW_FEEDBACK = 'view-feedback',
  VIEW_CAMPAIGNS = 'view-campaign',
  VIEW_ROUTINES = 'view-routines',
  VIEW_GEOFENCES = 'view-geofences',
  VIEW_INSIGHTS = 'view-insights',
}

export const history = [
  {
    id: 0,
    label: 'Import',
  },
  {
    id: 1,
    label: 'Export',
  },
];

export enum TimeUnit {
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
}

export const timeUnit = [
  {
    id: 0,
    label: TimeUnit.DAY,
  },
  {
    id: 1,
    label: TimeUnit.WEEK,
  },
  {
    id: 2,
    label: TimeUnit.MONTH,
  },
];

export enum ArithematicOperation {
  ADD = 'ADD',
  SUBTRACT = 'SUBTRACT',
}
export enum SearchType {
  ID = 'ID',
  NAME = 'NAME',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export const UserSearchType = [
  {
    value: SearchType.ID,
    label: 'Id',
  },
  {
    value: SearchType.NAME,
    label: 'Name',
  },
  {
    value: SearchType.PHONE,
    label: 'Phone',
  },
  {
    value: SearchType.EMAIL,
    label: 'Email',
  },
];

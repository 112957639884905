import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorBoundaryFallback, LoadingIndicator } from '@components';
import RouteLayout from '@routes/RouteLayout';
import store from '@store/store';

const App = () => (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
    <Suspense fallback={<LoadingIndicator />}>
      <Provider store={store}>
        <Router>
          <RouteLayout />
        </Router>
      </Provider>
    </Suspense>
  </ErrorBoundary>
);

export default App;

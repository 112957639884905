import { createSlice } from '@reduxjs/toolkit';
import { UserSearchType } from '@constants/common';
import { OptionType } from 'components/dropdown/types';
import { AdminFilters, AdminSlice } from '../type';

const filtersInitialState: AdminFilters = {
  pageIndex: 1,
  adminSearchText: '',
  adminSearchType: UserSearchType[1],
};

export const initialState: AdminSlice = {
  filters: {
    admin: filtersInitialState,
  },
  totalAdminCount: 0,
};

export const adminSlice = createSlice({
  name: 'Admin',
  initialState,
  reducers: {
    updatePageIndex: (state, { payload }: { payload: number }) => {
      state.filters.admin.pageIndex = payload;
    },
    updateAdminSearchText: (state, { payload }: { payload: string }) => {
      state.filters.admin.adminSearchText = payload;
    },
    updateAdminSearchType: (state, { payload }: { payload: OptionType }) => {
      state.filters.admin.adminSearchType = payload;
    },
  },
});

export const { updatePageIndex, updateAdminSearchText, updateAdminSearchType } =
  adminSlice.actions;

export default adminSlice.reducer;

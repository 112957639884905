import { IPageDescription } from '@containers/header/type';
import { ACIcon, FanIcon, AirPurifierIcon } from '@icons';
import { DeviceDetailsForm, UserAccessType } from '@containers/devices/type';
import RoutePaths from '@routes/RoutesPath';
import { DeviceVendor } from '@customTypes/common';
import { routeLabels } from '../containers/side-nav-bar/routes';

export enum DEVICE_STATUS {
  true = 'Online',
  false = 'Offline',
}

export const DEVICE_CATEGORY = {
  CEILING_FAN: 'Ceiling Fan',
  AC: 'AC',
  AIR_PURIFIER: 'Air Purifier',
  DOOR_LOCK: 'Door Lock',
};

export enum DEVICE_CATEGORY_KEYS {
  CEILING_FAN = 'CEILING_FAN',
  AC = 'AC',
  AIR_PURIFIER = 'AIR_PURIFIER',
  DOOR_LOCK = 'DOOR_LOCK',
}

export const DEVICE_CATEGORY_ICONS = {
  AC: ACIcon,
  CEILING_FAN: FanIcon,
  AIR_PURIFIER: AirPurifierIcon,
};

export const DeviceDetailsFields = [
  {
    id: 1,
    label: 'Device Name',
    name: DeviceDetailsForm.NAME,
  },
  {
    id: 2,
    label: 'Category',
    name: DeviceDetailsForm.CATEGORY,
  },
  {
    id: 3,
    label: 'Status',
    name: DeviceDetailsForm.STATUS,
  },
  {
    id: 4,
    label: 'Owner',
    name: DeviceDetailsForm.OWNER_NAME,
    IDName: DeviceDetailsForm.OWNER_ID,
  },
  {
    id: 5,
    label: 'Room',
    name: DeviceDetailsForm.ROOM_NAME,
    IDName: DeviceDetailsForm.ROOM_ID,
  },
  {
    id: 6,
    label: 'Location',
    name: DeviceDetailsForm.LOCATION,
  },
  {
    id: 7,
    label: 'Vendor',
    name: DeviceDetailsForm.VENDOR,
  },
  {
    id: 8,
    label: 'Model Name',
    name: DeviceDetailsForm.MODEL_NAME,
  },
  {
    id: 9,
    label: 'Paired At',
    name: DeviceDetailsForm.PAIRED_AT,
  },
  {
    id: 10,
    label: 'Last Filter Reset At',
    name: DeviceDetailsForm.LAST_FILTER_RESET_AT,
  },
];

export const deviceTabs = [
  {
    id: 1,
    label: 'Device Details',
  },
  {
    id: 2,
    label: 'Sharing Details',
  },
  {
    id: 3,
    label: routeLabels.routines,
  },
  {
    id: 4,
    label: routeLabels.geofencing,
  },
  {
    id: 5,
    label: routeLabels.ambientTemperature,
  },
  {
    id: 6,
    label: routeLabels.hoursOfUsage,
  },
  {
    id: 7,
    label: 'Insights',
  },
];

export const breadcrumbs: IPageDescription[] = [
  {
    path: RoutePaths.DEVICES,
    pageName: routeLabels.devices,
  },
];

export const AccessTypes = {
  [UserAccessType.member]: 'Member',
  [UserAccessType.owner]: 'Owner',
};

export enum DeviceActivityStatus {
  ON = 'on',
  OFF = 'off',
}

export const userType = [
  {
    label: 'Owner',
    value: UserAccessType.owner,
  },
  {
    label: 'Member',
    value: UserAccessType.member,
  },
];

export const vendorType = [
  {
    label: 'Tuya',
    value: DeviceVendor.TUYA,
  },
  {
    label: 'Gree',
    value: DeviceVendor.GREE,
  },
];

export const ModelName = {
  OASIS_CLASSIC: 'OASIS CLASSIC',
  OASIS_PRO_NO_LED: 'OASIS PRO NO LED',
  OASIS_PRO_RGB_LED: 'OASIS PRO RGB LED',
  OASIS_PRO_WY_LED: 'OASIS PRO WY LED',
  VORTEX: 'VORTEX',
  VORTEX_PRO: 'VORTEX PRO',
  AURA: 'AURA',
  ZERO: 'ZERO',
};

export const CATEGORY_OPTIONS = [
  { value: DEVICE_CATEGORY_KEYS.AC, label: 'AC' },
  { value: DEVICE_CATEGORY_KEYS.CEILING_FAN, label: 'Ceiling Fan' },
  { value: DEVICE_CATEGORY_KEYS.AIR_PURIFIER, label: 'Air Purifier' },
  { value: DEVICE_CATEGORY_KEYS.DOOR_LOCK, label: 'Door Lock' },
];

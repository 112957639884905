import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const BarGraph = ({ data, labels, chartOptions }) => {
  const chartData = {
    labels,
    datasets: data,
  };

  return <Bar data={chartData} options={chartOptions} />;
};

export default BarGraph;

import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import RoutePaths from './RoutesPath';

const LoginPage = lazy(() => import('@pages/LoginPage'));
const ForgotPasswordPage = lazy(() => import('@pages/ForgotPasswordPage'));

function PublicLayout() {
  return (
    <Routes>
      <Route path={RoutePaths.LOGIN} element={<LoginPage />} />
      <Route
        path={RoutePaths.FORGOT_PASSWORD}
        element={<ForgotPasswordPage />}
      />
      <Route
        path={RoutePaths.ALL}
        element={<Navigate to={RoutePaths.LOGIN} replace />}
      />
    </Routes>
  );
}

export default PublicLayout;

import { gql } from 'graphql-request';

export const GET_USERS = gql`
  query getUsers($input: ListUsersInput!, $pagination: PaginationInput) {
    getUsers(input: $input, pagination: $pagination) {
      totalCount
      nodes {
        email
        firstName
        id
        isOnBoarded
        lastName
        phone
        lastActiveAt
        createdAt
      }
    }
  }
`;

export const GET_USERS_FILTER = gql`
  query getUsersFilter($pagination: PaginationInput, $input: ListUsersInput!) {
    getUsers(pagination: $pagination, input: $input) {
      nodes {
        firstName
        id
        lastName
        lastActiveAt
        createdAt
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      email
      firstName
      id
      isOnBoarded
      lastName
      phone
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    #TODO Replace with id variable
    deleteUser(id: $id) {
      id
      email
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($id: ID!, $input: UpdateUserDetailsInput!) {
    updateCustomer(id: $id, input: $input) {
      id
    }
  }
`;

export const GET_ALL_PERMISSIONS_OF_USER = gql`
  query getAllPermissionsOfUser {
    getAllPermissionsOfUser {
      id
      name
    }
  }
`;

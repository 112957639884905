import { useEffect, useState } from 'react';

import { Thumbnail } from '@icons';

const Img: React.FC<{ url: string }> = ({ url }) => {
  const [imgUrl, setImgUrl] = useState('');
  useEffect(() => setImgUrl(url), [url]);
  const setThumbnailImage = () => setImgUrl(Thumbnail);

  return (
    <img
      src={imgUrl}
      alt='Unable to load'
      onError={setThumbnailImage}
      className='max-w-[26rem] max-h-56 rounded-lg cursor-pointer'
    />
  );
};

export default Img;

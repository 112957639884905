import { showNotifier } from '@containers/notification/reducer';

const handleApiErrorFactory = (store) => {
  const handleApiError = (message = 'Something went wrong') => {
    store.dispatch(
      showNotifier({
        message,
        type: 'error',
      })
    );
  };
  return handleApiError;
};

export { handleApiErrorFactory };
